import { motion } from "framer-motion";

const ButtonBuy = () => {
  return (
    <motion.a
      className="buybtn overflow-hidden text-figma-text border-figma-accent/80 border inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center rounded-lg"
      title="Ir a comprar tablas de loteria"
      href="https://buy.stripe.com/eVabMtcdV1l77Vm5kk"
      target="_blank"
    >
      Comprar
      <svg
        className="w-5 h-5 ml-2 -mr-1"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
          clipRule="evenodd"
        ></path>
      </svg>
    </motion.a>
  );
};

export default ButtonBuy;
